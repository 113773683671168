import React, { useEffect, useState } from "react";
import useAsync from "../UseAsync";
import { smartUserSignIn } from "../SignInApi";

import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

// GVA는 기존 페이지
const SmartSignInPre = ({ history }) => {
  const location = useLocation();
  const [state, setState] = useState({
    id: "",
    password: "",
    loginButtonState: "disabled",
    alertClassName: "",
    alertMessage: "",
  });
  const classes = useStyles();
  const [queryString] = useState(location);
  const [result, tryRefetch] = useAsync(
    () => smartUserSignIn(state.id, state.password, queryString["search"]),
    [],
    true
  );
  const { loading, data: responseResult } = result;

  const loginButtonState = () => {
    setState((state) => ({
      ...state,
      alertClassName: "",
      alertMessage: "",
    }));

    if (state.id.length > 0 && state.password.length) {
      setState((state) => ({
        ...state,
        loginButtonState: "",
      }));
    } else {
      setState((state) => ({
        ...state,
        loginButtonState: "disabled",
      }));
    }
  };

  const idInputOnChange = (event) => {
    setState((state) => ({
      ...state,
      id: event.target.value,
    }));
    loginButtonState();
  };

  const passwordInputOnChanage = (event) => {
    setState((state) => ({
      ...state,
      password: event.target.value,
    }));
    loginButtonState();
  };

  const loginButtonClick = () => {
    tryRefetch();
  };

  useEffect(() => {
    if (
      responseResult !== undefined &&
      responseResult !== null &&
      responseResult.code === 200
    ) {
      console.log(responseResult.data.location);
      window.location.href = responseResult.data.location;
    } else if (
      responseResult !== undefined &&
      responseResult !== null &&
      responseResult.code !== 200
    ) {
      setState((state) => ({
        ...state,
        alertClassName: "container-login100-form-btn container-alert100 m-b-15",
        alertMessage: "아이디 또는 비밀번호가 잘못되었습니다.",
      }));
    }
  }, [responseResult]);

  return (
    <div className="wrap-login100 p-t-50 p-b-90">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={"primary"} />
      </Backdrop>
      <div className="login100-form validate-form flex-sb flex-w">
        <div>
          <input type="hidden" name="clientId" value="" />
        </div>
        <div className="flex-c w-full" style={{ padding: "20px" }}>
          <img
            width="100%"
            height="100%"
            alt="logo"
            src={process.env.PUBLIC_URL + "/images/navien-smart-logo.png"}
          ></img>
        </div>
        <div className={state.alertClassName}>
          <p className="text-white fs-16">{state.alertMessage}</p>
        </div>
        <div className="wrap-input100 validate-input m-b-16">
          <input
            className="input100"
            type="text"
            name="username"
            placeholder="아이디"
            onKeyUp={idInputOnChange}
          ></input>
          <span className="focus-input100"></span>
        </div>
        <div className="wrap-input100 validate-input m-b-16">
          <input
            className="input100"
            type="password"
            name="password"
            placeholder="비밀번호"
            onKeyUp={passwordInputOnChanage}
          ></input>
          <span className="focus-input100"></span>
        </div>
        <div className="container-login100-form-btn button-activate m-t-16">
          <button
            className="login100-form-btn"
            onClick={loginButtonClick}
            disabled={state.loginButtonState}
          >
            로그인
          </button>
        </div>
        <div className="flex-sb-m w-full m-t-10">
          <p>
            로그인하면 Google이 귀하의 기기를 제어할 수 있는 권한을 부여하는
            것입니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SmartSignInPre;
